import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import group from './modules/group'
import order from './modules/order'
import product from './modules/product'
import ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        group,
        order,
        product,
        ui,
    },
})
