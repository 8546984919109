import {
    forEach as _forEach,
} from 'lodash'

const defaultState = {
    appBar: {
        color: undefined,
        elevateOnScroll: undefined,
        dark: undefined,
        light: undefined,
        navigationIcon: undefined,
        title: undefined,
        actionItems: [],
        // Contextual
        contextual: {
            isEnabled: false,
            title: undefined,
            actionItems: [],
        },
    },
    navigationDrawer: {
        isShown: false,
    },
}

const state = {
    appBar: defaultState.appBar,
    navigationDrawer: defaultState.navigationDrawer,
}
const getters = {}

const mutations = {
    setAppBar(state, payload) {
        state.appBar = { ...defaultState.appBar, ...payload }
    },
    setNavigationDrawer(state, payload) {
        state.navigationDrawer = { ...defaultState.navigationDrawer, ...payload }
    },
}
const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
