import Vue from 'vue'

import {
    forEach as _forEach,
    orderBy as _orderBy,
} from 'lodash'

import ProductModel from '@/models/ProductModel'

import ProductService from '@/services/products.service'

// -----------------------------------------------------------------------------

const state = {
    products: {},
    selectedQuantity: {},
}

const getters = {
    getProducts: state => {
        return _orderBy(state.products, ['sort'], ['asc'])
    },

    getProduct: state => productId => {
        return state.products[productId] || null
    },

    getSelectedQuantity: state => productId => {
        return state.selectedQuantity[productId] || 0
    },
}

const mutations = {
    setProducts(state, payload) {
        state.products = {}

        _forEach(payload, item => {
            const Product = new ProductModel(item)

            Vue.set(state.products, Product.id, Product)
        })
    },

    setProduct(state, payload) {
        const Product = new ProductModel(payload)

        Vue.set(state.products, Product.id, Product)
    },

    setSelectedQuantity(state, payload) {
        Vue.set(state.selectedQuantity, payload.productId, payload.quantity)
    },

    clearSelectedQuantity(state) {
        state.selectedQuantity = {}
    },
}

const actions = {
    fetchProducts({ commit }) {
        return new Promise((resolve, reject) => {
            ProductService.getList()
                .then(response => {
                    if (Array.isArray(response)) {
                        response.forEach(item => {
                            commit('setProduct', item)
                            if (item.groups) {
                                commit('group/setGroup', item.groups, { root: true })
                            }
                        });
                    }

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
