import axios from 'axios'
import Http from './http'
import { ErrorWrapper } from './util'
import $store from '../store'
import $router from '../router'

const API_URL = process.env.VUE_APP_API_URL

const CLIENT_ID = 1;
const CLIENT_SECRET = 'OHcSKwZGJ9M2AHtKDZ8NFbV4Qz5sDZgZAwSO6C8i';

/**
 ******************************
 * @API
 ******************************
 */

export function register ({ phone }) {
    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/auth/register`, { phone })
            .then(response => {
                return resolve(response.data)
            }).catch(error => reject(new ErrorWrapper(error.response ? error.response.data : error)))
    })
}

export function makeLogin ({ phone, password }) {
    const obj = {
        grant_type: 'password',
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        username: phone,
        password,
    };
    const data = Object.keys(obj)
        .map((key, index) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/oauth/token`, 
            data,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then(response => {
                _setAuthData(response)
                return resolve(response.data)
            }).catch(error => reject(new ErrorWrapper(error.response ? error.response.data : error)))
    })
}

export function makeLogout () {
    return new Promise((resolve, reject) => {
        new Http({ auth: true }).axios.post(`auth/logout`)
            .then(response => {
                _resetAuthData()
                goToAuthFallback()
                return resolve(response.data)
            }).catch(error => reject(new ErrorWrapper(error.response ? error.response.data : error)))
    })
}

export function refreshTokens () {
    return new Promise((resolve, reject) => {
        axios.post(`${API_URL}/oauth/token`, { 
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(),
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
        })
            .then(response => {
                _setAuthData(response)
                return resolve(response.data)
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const errors = error.response.data.errors
                    if (errors[0] && errors[0].code == 8) {
                        _resetAuthData()
                        goToAuthFallback()
                    }
                }
                
                return reject(new ErrorWrapper(error.response ? error.response.data : error))
            })
    })
}

/**
 ******************************
 * @methods
 ******************************
 */

export function isAccessTokenExpired () {
    const accessTokenExpDate = $store.state.auth.accessTokenExpDate - 1
    const nowTime = Math.floor(new Date().getTime() / 1000)

    return accessTokenExpDate <= nowTime
}

export function getRefreshToken () {
    return localStorage.getItem('refreshToken')
}

export function getAccessToken () {
    return localStorage.getItem('accessToken')
}

export function goToAuthFallback () {
    $router.push({ name: 'start' })
}

function _resetAuthData () {
    // reset userData in store
    $store.commit('user/SET_CURRENT_USER', {})
    $store.commit('auth/SET_ATOKEN_EXP_DATE', null)
    // reset tokens in localStorage
    localStorage.setItem('refreshToken', '')
    localStorage.setItem('accessToken', '')
}

function _setAuthData (response) {
    const nowTime = Math.floor(new Date().getTime() / 1000)

    localStorage.setItem('refreshToken', response.data.refresh_token)
    localStorage.setItem('accessToken', response.data.access_token)
    $store.commit('auth/SET_ATOKEN_EXP_DATE', nowTime + response.data.expires_in)
}
