<template>
    <div id="app">
        <vue-extend-layouts/>
        <apple-modal v-if="showAppleModal" :show-modal.sync="showAppleModal" />
    </div>
</template>

<script>
    import VueExtendLayouts from 'vue-extend-layout'

    export default {
        name: 'App',

        components: {
            VueExtendLayouts,
            AppleModal: () => import('@/components/app/AppleInstallModal')
        },

        data() {
            return {
                showAppleModal: false,
            }
        },

        watch: {
            '$route': {
                handler: function () {
                    if (this.$vuetify.breakpoint.mdAndDown) {
                        this.$store.commit('ui/setNavigationDrawer', { isShown: false })
                    }
                },
                immediate: true,
            },
        },

        created() {
            // Событие обновления приложения
            document.addEventListener('swUpdated', eventData => {
                this.showToast('Доступно обновление', {
                    action: {
                        text : 'Обновить',
                        onClick : () => {
                            if (eventData.detail && eventData.detail.waiting) {
                                eventData.detail.waiting.postMessage('skipWaiting')
                            }

                            window.location.reload()
                        }
                    },
                    duration: null,
                })
            }, false)
        },

        mounted() {
            // Диалог установки приложения на iphone
            if (!navigator.standalone) {
                if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
                    setTimeout(() => {
                        this.showAppleModal = true
                    }, 1000)
                }
            }
        }
    }
</script>
