import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import * as AuthService from '../services/auth.service'

// -----

const Start = () => import ('@/views/Start')

const Auth = () => import ('@/views/Auth')

// -----

const Purchase = () => import ('@/views/Purchase')

const PurchaseCatalog = () => import ('@/views/purchase/Catalog')
const PurchaseCatalogIndex = () => import ('@/views/purchase/catalog/Index')

const PurchaseOrder = () => import ('@/views/purchase/Order')
const PurchaseOrderIndex = () => import ('@/views/purchase/order/Index')

const PurchaseHistory = () => import ('@/views/purchase/History')
const PurchaseHistoryIndex = () => import ('@/views/purchase/history/Index')
const PurchaseHistoryOrder = () => import ('@/views/purchase/history/Order')

// -----

const PrivacyPolicy = () => import ('@/views/PrivacyPolicy')

// -----

Vue.use(VueRouter)

const routes = [
    // Root
    {
        path: '/',
        name: 'root',
        redirect: {name: 'purchaseCatalog'},
    },    
    // Start
    {
        path: '/start',
        name: 'start',
        component: Start,
        meta: {
            layout: 'start',
        },
    },
    // Auth
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: {
            layout: 'auth',
        },
    },
    // Purchase
    {
        path: '/purchase',
        component: Purchase,
        meta: {
            needAuth: true,
            isShownBottomNavigation: true,
        },
        children: [
            {
                component: PurchaseCatalog,
                path: 'catalog',
                meta: {
                    needAuth: true,
                },
                children: [
                    {
                        component: PurchaseCatalogIndex,
                        path: '',
                        name: 'purchaseCatalog',
                        meta: {
                            layout: 'purchase',
                            needAuth: true,
                        },
                    },
                ],
            },
            {
                component: PurchaseOrder,
                path: 'order',
                meta: {
                    needAuth: true,
                },
                children: [
                    {
                        component: PurchaseOrderIndex,
                        path: '',
                        name: 'purchaseOrder',
                        meta: {
                            layout: 'purchase',
                            needAuth: true,
                        },
                    },
                ],
            },
            {
                component: PurchaseHistory,
                path: 'history',
                meta: {
                    needAuth: true,
                },
                children: [
                    {
                        component: PurchaseHistoryIndex,
                        path: '',
                        name: 'purchaseHistory',
                        meta: {
                            layout: 'purchase',
                            needAuth: true,
                        },
                    },
                    {
                        component: PurchaseHistoryOrder,
                        path: 'order/:orderId',
                        name: 'purchaseHistoryOrder',
                        meta: {
                            layout: 'purchase',
                            needAuth: true,
                        },
                    },
                ],
            },
        ],
    },
    // Other
    {
        path: '/privacy-policy',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
        meta: {
            layout: 'default',
        },
    },
]

const router = new VueRouter({
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.needAuth)) {
        if (!AuthService.getRefreshToken()) {
            next({name: 'start'})
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
