import Http from './http'
import { ErrorWrapper } from './util'

export default class BaseService {
    constructor () {
        if (!this.entity) {
            throw new Error('Child service class not provide entity')
        }
    }

    /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

    request (status = { auth: true }) {
        return new Http(status)
    }

    errorWrapper (...rest) {
        return new ErrorWrapper(...rest)
    }

    /**
    * ------------------------------
    * @API_CALLS_PRIVATE
    * ------------------------------
    */

    getList (parameters = {}) {
        const params = {
            ...parameters
        }
        return new Promise((resolve, reject) => {
            return this.request().get(`${this.entity}`, { params })
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(this.errorWrapper(error.response ? error.response.data : error))
                })
        })
    }

    getById (id = window.required()) {
        return new Promise((resolve, reject) => {
            return this.request().get(`${this.entity}/${id}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(this.errorWrapper(error.response ? error.response.data : error))
                })
        })
    }

    create (data = window.required()) {
        return new Promise((resolve, reject) => {
            return this.request().axios.post(`${this.entity}`, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(this.errorWrapper(error.response ? error.response.data : error))
                })
        })
    }

    update (id = window.required(), data = window.required()) {
        return new Promise((resolve, reject) => {
            return this.request().patch(`${this.entity}/${id}`, data)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(this.errorWrapper(error.response ? error.response.data : error))
                })
        })
    }

    remove (id = window.required()) {
        return new Promise((resolve, reject) => {
            return this.request().delete(`${this.entity}/${id}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(this.errorWrapper(error.response ? error.response.data : error))
                })
        })
    }
}
