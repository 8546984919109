import BaseModel from './BaseModel'

export default class ProductModel extends BaseModel {
    get schema() {
        return {
            id: ['id', undefined, value => value],
            name: ['name', undefined, value => value],
            weight: ['weight', undefined, value => parseInt(value)],
            price: ['price', undefined, value => parseFloat(value)],
            groupId: ['groupId', undefined, value => value],
            previewPicture: ['previewPicture', undefined, value => value],
        }
    };

    static attributeLabels = {
        id: 'ID',
        name: 'Название',
        weight: 'Вес',
        price: 'Цена',
        groupId: 'Группа',
    }
}
