import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VuetifyColors from 'vuetify/es5/util/colors'

// Icons
import { library as iconLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faPhone as fasPhone,
    faBars as farBars,
    faBreadSlice as farBreadLoaf,
    faHistory as farHistory,
    faReceipt as farReceipt,
    faSignOutAlt as farSignOut,
    faTimes as farTimes,
    faUserShield as farUserShield,
} from '@fortawesome/free-solid-svg-icons'

import {
    faArrowAltCircleLeft as farArrowLeft,
    faSadTear as farSadTear,
} from '@fortawesome/free-regular-svg-icons'

iconLibrary.add(
    // Solid
    fasPhone,
    // Regular
    farArrowLeft,
    farBars,
    farBreadLoaf,
    farHistory,
    farReceipt,
    farSadTear,
    farSignOut,
    farTimes,
    farUserShield,
)

const MY_ICONS = {
    // complete: '...',
    // cancel: '...',
    'close': { component: FontAwesomeIcon, props: { icon: ['far', 'times'] } },
    // delete: '...', // delete (e.g. v-chip close)
    clear: { component: FontAwesomeIcon, props: { icon: ['far', 'times'] } },
    // success: '...',
    // info: '...',
    // warning: '...',
    // error: '...',
    // prev: '...',
    // next: '...',
    // checkboxOn: '...',
    // checkboxOff: '...',
    // checkboxIndeterminate: '...',
    // delimiter: '...', // for carousel
    // sort: '...',
    // expand: '...',
    // menu: '...',
    // subgroup: '...',
    // dropdown: '...',
    // radioOn: '...',
    // radioOff: '...',
    // edit: '...',
    // ratingEmpty: '...',
    // ratingFull: '...',
    // ratingHalf: '...',
    // loading: '...',
    // first: '...',
    // last: '...',
    // unfold: '...',
    // file: '...',
    arrowLeft: { component: FontAwesomeIcon, props: { icon: ['far', 'arrow-left'] } },
    bars: { component: FontAwesomeIcon, props: { icon: ['far', 'bars'] } },
    breadLoaf: { component: FontAwesomeIcon, props: { icon: ['far', 'bread-loaf'] } },
    history: { component: FontAwesomeIcon, props: { icon: ['far', 'history'] } },
    phone: { component: FontAwesomeIcon, props: { icon: ['fas', 'phone'] } },
    receipt: { component: FontAwesomeIcon, props: { icon: ['far', 'receipt'] } },
    sadTear: { component: FontAwesomeIcon, props: { icon: ['far', 'sad-tear'] } },
    signOut: { component: FontAwesomeIcon, props: { icon: ['far', 'sign-out'] } },
    userShield: { component: FontAwesomeIcon, props: { icon: ['far', 'user-shield'] } },

}

Vue.component('font-awesome-icon', FontAwesomeIcon)

// -----

Vue.use(Vuetify)

const opts = {
    icons: {
        values: MY_ICONS,
    },
    theme: {
        themes: {
            light: {
                primary: '#f3af49',
                secondary: '#111113',
            },
        },
    },
}

export default new Vuetify(opts)
