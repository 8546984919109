export default class BaseModel {
    constructor(src = {}) {
        this.buildModel(src, this.schema)
    };

    get schema() {
        throw new Error('Model schema not defined.')
    };

    buildModel(src = {}, schema = {}) {
        Object.keys(schema).forEach(schemaProp => {
            const srcProp = schema[schemaProp][0]
            const srcPropType = typeof src[srcProp]
            if (['number', 'boolean', 'string', 'object'].includes(srcPropType) && ![undefined, null].includes(src[srcProp])) {
                this[schemaProp] = schema[schemaProp][2] !== undefined ? schema[schemaProp][2](src[srcProp]) : src[srcProp]
            } else {
                this[schemaProp] = schema[schemaProp][1]
            }
        })
    };
};
