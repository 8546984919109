import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage (status) {
    let message = ''
    switch (status) {
        case 200:
            message = 'All done. Request successfully executed'
            break
        case 201:
            message = 'Data successfully created'
            break
        case 400:
            message = 'Bad Request'
            break
        case 401:
            message = 'Need auth'
            break
        case 404:
            message = 'Not found'
            break
        case 422:
            message = 'Unprocessable Entity'
            break
        case 500:
            message = 'Internal Server Error'
            break
        case 503:
            message = 'Service Unavailable'
            break
        default:
            message = 'Something wrong. Client default error message'
            break
    }
    return message
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
    constructor (error, message) {
        super()
        if (error.errors) {
            this.errors = error.errors
        } else {
            this.errors = [{
                title: 'exception',
                detail: 'Не известная ошибка сервера'
            }]
        }
    }
}

/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
 */
export function clearData (data) {
    let result = {}
    forEach(data, (item, propName) => {
        if (isArray(item) && item.length) {
            result[propName] = item
        }
        if (!isArray(item) && item && (propName !== 'id')) {
            result[propName] = item
        }
    })
    return result
}