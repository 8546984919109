import BaseModel from './BaseModel'
import OrderStatusModel from './OrderStatusModel'

export default class OrderModel extends BaseModel {
    get schema() {
        return {
            id: ['id', undefined, value => value],
            number: ['number', undefined, value => value],
            status: ['order_status', undefined, value => new OrderStatusModel(value)],
            sum: ['sum', undefined, value => parseFloat(value)],
            createdAt: ['created_at', undefined, value => value],
            commentDelivery: ['comment_delivery', undefined, value => value],
            commentQuality: ['comment_quality', undefined, value => value],
        }
    };

    static attributeLabels = {
        id: 'ID',
        number: 'Номер',
        status: 'Статуса',
        sum: 'Сумма заказа',
        createdAt: 'Дата создания',
        commentDelivery: 'Пожелание по времени доставки',
        commentQuality: 'Комметарий по качеству обслуживания',
    }
}
