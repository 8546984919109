export default {
    methods: {
        showToast(message, options) {
            const defaultOptions = {
                action : {
                    text : 'Закрыть',
                    onClick : (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                },
                duration: 5000,
                type: 'info'
            }

            this.$toasted.show(message, {...defaultOptions, ...options})
        },

        showToasts(messages, options) {
            if (Array.isArray(messages)) {
                messages.forEach(message => { this.showToast(message, options) })
            } else {
                this.showToast(messages, options)
            }
        },

        showToastError(errors) {
            if (!Array.isArray(errors)) {
                errors = [errors]
            }

            errors = errors.map(error => {
                if (typeof(error) === 'object' && error.detail) {
                    return error.detail
                } else {
                    return error
                }
            })

            this.showToasts(errors, {
                duration: null,
                type: 'error',
            })
        },

        showToastSuccess(messages) {
            this.showToasts(messages, {
                type: 'success',
            })
        },
    }
}