/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import Kitsu from 'kitsu'  

import * as authService from '../services/auth.service'

const API_URL = process.env.VUE_APP_API_URL

export default class Http {
    static isTokenRefreshing = false
    static refreshingPromise = null

    constructor (status) {
        this.isAuth = status && status.auth === true
        this.instance = new Kitsu({
            baseURL: API_URL 
        })

        return this.init()
    }

    init () {
        if (this.isAuth) {
            this.instance.interceptors.request.use(request => {
                request.headers['Authorization'] = 'Bearer ' + authService.getAccessToken()
                // if access token expired and refreshToken is exist >> go to API and get new access token
                if (!authService.getRefreshToken()) {
                    authService.goToAuthFallback()
                    return
                }

                if (authService.isAccessTokenExpired()) {
                    if (Http.isTokenRefreshing === false) {
                        Http.isTokenRefreshing = true
                        Http.refreshingPromise = authService.refreshTokens()
                            .then(response => {
                                request.headers['Authorization'] = 'Bearer ' + response.access_token
                                Http.isTokenRefreshing = false
                                return request
                            })
                            .catch(error => {
                                return Promise.reject(error)
                            })

                        return Http.refreshingPromise
                    } else {
                        Http.refreshingPromise
                            .then(response => {
                                request.headers['Authorization'] = 'Bearer ' + response.access_token
                                return request
                            })

                        return Http.refreshingPromise
                    }
                } else {
                    return request
                }
            }, error => {
                return Promise.reject(error)
            })

            return this.instance
        }

        return this.instance
    }
}