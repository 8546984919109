import Vue from 'vue'

import {
    forEach as _forEach,
    orderBy as _orderBy,
} from 'lodash'

import OrderModel from '@/models/OrderModel'

import OrderService from '@/services/orders.service'

// -----------------------------------------------------------------------------

const state = {
    orders: {},
}

const getters = {
    getOrders: state => {
        return _orderBy(state.orders, ['sort'], ['asc'])
    },

    getOrder: state => orderId => {
        return state.orders[orderId] || null
    },
}

const mutations = {
    setOrders(state, payload) {
        state.orders = {}

        _forEach(payload, item => {
            const Order = new OrderModel(item)

            Vue.set(state.orders, Order.id, Order)
        })
    },

    setOrder(state, payload) {
        const Order = new OrderModel(payload)

        Vue.set(state.orders, Order.id, Order)
    },
}

const actions = {
    fetchOrders({ commit }) {
        return new Promise((resolve, reject) => {
            OrderService.getList()
                .then(response => {
                    if (Array.isArray(response)) {
                        commit('setOrders', response)
                    }

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchById({ commit }, id) {
        return new Promise((resolve, reject) => {
            OrderService.getById(id)
                .then(response => {
                    commit('setOrder', response)

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
