function declension(value, declensions) {
    declensions = declensions || ['элемент', 'элемента', 'элементов'];

    if (declensions.length === 3) {
        return value + ' ' + declensions[(value % 10 === 1 && value % 100 !== 11) ? 0 : value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20) ? 1 : 2];
    }

    return false;
}

export default declension;