import BaseModel from './BaseModel'

export default class OrderStatusModel extends BaseModel {
    get schema() {
        return {
            id: ['id', undefined, value => parseInt(value)],
            name: ['name', undefined, value => value],
            color: ['color', undefined, value => value],
        }
    };

    static attributeLabels = {
        id: 'ID',
        name: 'Название',
        color: 'Цвет',
    }
}
