import Vue from 'vue'

import {
    forEach as _forEach,
    orderBy as _orderBy,
} from 'lodash'

import GroupModel from '@/models/GroupModel'

// -----------------------------------------------------------------------------

const state = {
    groups: {},
}

const getters = {
    getGroups: state => {
        return _orderBy(state.groups, ['sort'], ['asc'])
    },

    getGroup: state => groupId => {
        return state.groups[groupId] || null
    },
}

const mutations = {
    setGroups(state, payload) {
        state.groups = {}

        _forEach(payload, item => {
            const Group = new GroupModel(item)

            Vue.set(state.groups, Group.id, Group)
        })
    },

    setGroup(state, payload) {
        const Group = new GroupModel(payload)

        Vue.set(state.groups, Group.id, Group)
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
