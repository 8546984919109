import BaseModel from './BaseModel'

export default class GroupModel extends BaseModel {
    get schema() {
        return {
            id: ['id', undefined, value => value],
            name: ['name', undefined, value => value],
        }
    };

    static attributeLabels = {
        id: 'ID',
        name: 'Название',
    }
}
